//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import TransactionListTable from '@/modules/transaction/components/transaction-list-table.vue';

export default {
  name: 'app-transaction-list-page',

  components: {
    [TransactionListTable.name]: TransactionListTable,
  },
  computed:{
    ...mapGetters({
      loading: 'transaction/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      return this.currentLanguageCode == 'ar'
    },
  },
};
